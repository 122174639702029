import React, { useEffect, useContext } from "react"

import GlobalContext from "../../context/GlobalContext"

const PageWrapper = ({
  children,
  headerDark = false,
  footerDark = false,
  footerHide = false,
}) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (headerDark) {
      gContext.goHeaderDark()
    } else {
      gContext.goHeaderLight()
    }

    if (footerDark) {
      gContext.goFooterDark()
    } else {
      gContext.goFooterLight()
    }
    if (footerHide) {
      gContext.goFooterHide()
    } else {
      gContext.goFooterShow()
    }
  }, [gContext, headerDark, footerDark, footerHide])

  return <>{children}</>
}

export default PageWrapper
