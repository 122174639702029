import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import { Title, Button, Section, Box, Text, Input } from "../components/Core"

import PageWrapper from "../components/PageWrapper"

import { t } from "@lingui/macro"
import SEO from "../components/SEO"
import { LocalizedLink } from "gatsby-theme-i18n"

const FormStyled = styled.form``

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding: 50px;
`

const Contact1 = ({ location }) => {
  const handleSubmit = (e) => {
    if (typeof window !== "undefined") {
      window.plausible("contact_form_submission", {
        props: { path: document.location.pathname },
      })
    }
  }

  return (
    <>
      <PageWrapper footerDark>
        <SEO
          pathname={location.pathname}
          title={t`Kontakt`}
          description={t`Kontaktieren Sie uns für Beratung und Engineering für künstliche Intelligenz. AMAI hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten.`}
        />
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero">
                    {t`Lassen Sie uns über Ihr Projekt sprechen.`}
                  </Title>
                  <Text>
                    {t`Wir beraten Sie unverbindlich und kostenfrei`}
                    <br className="d-none d-md-block" />{" "}
                    {t`über die Möglichkeiten von KI für Ihr Unternehmen.`}
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-lg-5">
              <Col lg="6">
                <Title
                  css={{ fontSize: 32, color: "#000", paddingBottom: 30 }}
                  as="h3"
                >
                  {t`Nachricht schreiben`}
                </Title>
                <FormStyled
                  name="contact"
                  method="post"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <Box mb={3}>
                    <Input type="text" name="name" placeholder={t`Ihr Name`} />
                  </Box>
                  <Box mb={3}>
                    <Input
                      type="email"
                      name="email"
                      placeholder={t`Email Adresse`}
                    />
                  </Box>
                  <Box mb={3}>
                    <Input
                      type="text"
                      name="subject"
                      placeholder={t`Betreff`}
                    />
                  </Box>

                  <Box mb={3}>
                    <Input
                      type="text"
                      as="textarea"
                      placeholder={t`Ihre Nachricht`}
                      rows={4}
                      name="text"
                    />
                  </Box>
                  <Box mb={3} className="d-none">
                    <Input
                      type="text"
                      name="bot-field"
                      placeholder={t`Dieses Feld nicht ausfüllen, wenn Sie ein Mensch sind`}
                    />
                  </Box>

                  <Button
                    width="100%"
                    type="submit"
                    borderRadius={10}
                    onClick={handleSubmit}
                  >
                    {t`Nachricht senden`}
                  </Button>
                </FormStyled>
              </Col>
              <Col lg={5} className="offset-lg-1 mt-5 mt-lg-0">
                <WidgetWrapper>
                  <Box className="mb-5">
                    <LocalizedLink to="/appointment">
                      <Button width="100%" type="submit" borderRadius={10}>
                        {t`Online Termin vereinbaren`}
                      </Button>
                    </LocalizedLink>
                  </Box>
                  <Box className="mb-5">
                    <Title variant="card" fontSize="24px">
                      {t`Anrufen`}
                    </Title>
                    <Text>
                      <a href="tel:004972127664476">+49 721 27664476</a>
                    </Text>
                  </Box>
                  <Box className="mb-5">
                    <Title variant="card" fontSize="24px">
                      {t`Mail senden`}
                    </Title>
                    <Text>
                      <a href="mailto:hi@am.ai">hi@am.ai</a>
                    </Text>
                  </Box>
                  <Box>
                    <Title variant="card" fontSize="24px">
                      {t`Hauptquartier`}
                    </Title>
                    <Text>
                      <a
                        href="https://goo.gl/maps/kfAuGGieUp2DeedYA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Schwarzwaldstraße 39
                      </a>
                    </Text>
                    <Text>
                      <a
                        href="https://goo.gl/maps/kfAuGGieUp2DeedYA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        76137 Karlsruhe, {t`Deutschland`}
                      </a>
                    </Text>
                  </Box>
                </WidgetWrapper>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Contact1
